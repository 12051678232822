import { Collapse } from 'antd5';
import styled from 'styled-components';

export const AssetFilterInnerContainer = styled.div`
  padding: 12px;
  width: 100%;

  .ant-alert {
    margin-bottom: 0.5rem;
  }
`;

export const AssetSelectContainer = styled.div<{ selectWidth?: string }>`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.75rem;
  width: 100%;

  > span {
    margin-right: 0.5rem;
  }

  .ant-select {
    ${({ selectWidth }) => (selectWidth ? `width: ${selectWidth}` : 'flex-grow: 1')};
    max-width: calc(100% - 80px);
  }
`;

export const ComponentFiltersCollapse = styled(Collapse)`
  margin: 0 0 8px 0;
  width: 100%;

  .ant-collapse-expand-icon {
    padding-inline-end: 0 !important;
  }

  .ant-collapse-content-box {
    background: white;
    padding: 12px !important;
  }
`;
