import { useEffect, useState } from 'react';
import { message } from 'antd';
import { useStandardFields } from '../useStandardFields';
import { isEqual } from 'lodash';
import { getContextualColumns, sortAndMapFieldsToColumns } from './utils';
import { useFeatures } from 'utils/features';
import GET_WORK_CONTAINER_VALUES from 'horizon/routes/Settings/WorkContainerFields/queries/GetWorkContainerValues.atlas.gql';
import {
  useSaveWorkContainerFieldValuesMutation,
  useGetWorkContainerFieldsQuery,
  useGetWorkContainerValuesLazyQuery,
  AtlasGqlWorkContainerTypeOption,
  AtlasGqlTask,
  useGetTrustedOrganizationsQuery,
  AtlasGqlOrganization,
} from 'types/atlas-graphql';

import { usePermissions } from 'utils/usePermissions';

type useHeaderFieldsProps = {
  containerId: string;
  type: AtlasGqlWorkContainerTypeOption | string;
  additionalFields: any[];
  // FIXME JDJ: This could eventually be used to replace both containerId and additional fields
  // But that's a large refactor.
  workContainer?: AtlasGqlTask | undefined | null;
  // This component uses a combination of both rules and the isEditButtonVisible prop
  // to determine whether to show the edit button.
  // This is to continue to deprecate the use of rules going forward but need a way
  // to dynamically show the button for ongoing work. - AN 9/27/23
  isEditButtonVisible?: boolean | undefined;
  onSave: (input: any) => Promise<void> | undefined;
};

// Fetches and formats the header fields for a given work container
// If no work container is given, fetches and formats the header fields for the given work contianer type
export function useHeaderFields(props: useHeaderFieldsProps) {
  const { features } = useFeatures();
  const { FOR_ORGANIZATION_EDIT } = features;

  const { HasRole } = usePermissions();
  const isEditor = HasRole({ role: 'role-editor' });

  const { containerId, type, additionalFields, workContainer, isEditButtonVisible, onSave } = props;
  const [currentFields, setCurrentFields] = useState<any[]>([]);
  const [mergedAdditionalFields, setMergedAdditionalFields] = useState<any[]>([]);
  const [fetchedAdditionalFields, setFetchedAdditionalFields] = useState<any[]>([]);
  const [trustedOrganizations, setTrustedOrganizations] = useState<any[]>([]);
  const [getWorkContainerValues, { data }] = useGetWorkContainerValuesLazyQuery();

  const workFieldsResult = useGetWorkContainerFieldsQuery({
    variables: { type: type as AtlasGqlWorkContainerTypeOption },
    skip: !type,
  });

  const [saveWorkContainerFieldValues, { loading }] = useSaveWorkContainerFieldValuesMutation({
    refetchQueries: [{ query: GET_WORK_CONTAINER_VALUES, variables: { containerId } }],
    awaitRefetchQueries: true,
  });

  const isForOrgSet =
    workContainer && 'forOrganization' in workContainer && workContainer?.forOrganization;

  const { data: trustedOrgsData } = useGetTrustedOrganizationsQuery({
    /* 
      Query for trustedOrganizations only if:
      - The workContainer has a non-null value set for forOrganization. We need this to resolve the name of the org.
      - The user has the `role-editor` role.
      - The user has the `FOR_ORGANIZATION_EDIT` feature flag. 
    */
    skip: !(workContainer && (isForOrgSet || isEditor || FOR_ORGANIZATION_EDIT)),
  });
  const standardFields = useStandardFields(containerId, isEditButtonVisible);

  useEffect(() => {
    if (trustedOrgsData && trustedOrgsData?.trustedOrganizations)
      setTrustedOrganizations(trustedOrgsData.trustedOrganizations);
  }, [trustedOrgsData]);

  useEffect(() => {
    if (data?.task?.additionalFields) setFetchedAdditionalFields(data.task.additionalFields);
  }, [data]);

  useEffect(() => {
    if (!additionalFields && containerId) {
      getWorkContainerValues({ variables: { containerId } });
    } else if (additionalFields?.length > 0) {
      setMergedAdditionalFields(additionalFields);
    }
  }, [additionalFields, containerId, getWorkContainerValues]);

  useEffect(() => {
    if (fetchedAdditionalFields && fetchedAdditionalFields.length > 0) {
      setMergedAdditionalFields(fetchedAdditionalFields);
    }
  }, [fetchedAdditionalFields]);

  if (!workFieldsResult) {
    message.error('Unable to fetch Task data');
  }
  const workContainerFields = workFieldsResult?.data?.workContainerFields ?? [];
  const allFieldsLoading = workFieldsResult?.loading ?? false;

  useEffect(() => {
    if ((mergedAdditionalFields.length || workContainerFields.length) && !loading) {
      const allFieldsAndValues = workContainerFields.map(field => {
        const fieldWithData = mergedAdditionalFields.find(adField => adField.id === field.id);
        if (fieldWithData) {
          return fieldWithData;
        }
        return field;
      });
      if (!isEqual(currentFields, allFieldsAndValues)) {
        setCurrentFields(allFieldsAndValues);
      }
    }
  }, [mergedAdditionalFields, workContainerFields, loading, currentFields, setCurrentFields]);

  const workContainerColumns = sortAndMapFieldsToColumns(
    [...currentFields],
    type,
    standardFields,
    containerId,
    saveWorkContainerFieldValues,
    isEditButtonVisible
  );

  const contextualColumns = workContainer
    ? getContextualColumns(
        workContainer,
        isEditor,
        features,
        trustedOrganizations as AtlasGqlOrganization[],
        onSave
      )
    : [];

  const finalColumns = workContainer
    ? [...workContainerColumns, ...contextualColumns]
    : workContainerColumns;

  return {
    workContainerColumns: finalColumns,
    fieldsLoading: allFieldsLoading,
  };
}
