import { useRef, useState, useMemo, useEffect } from 'react';

import { Button } from 'antd5';
import { checkCr2, convertPathToJpg, getFileDirectoryPath } from './helpers';
import { StyledForm } from './FileUploader.style';
import { UploadContext, useUploadContext } from './UploadContext';
import {
  ConfirmProps,
  FileData,
  FilteredFiles,
  ModalAction,
  ModalActionArgs,
  ModalProps,
  ModalUploadCompleteInput,
  UploadFile,
} from './types';
import { OnCompleteStatus } from 'components/FileUploader/Modal/FileUploadModal';
import { ModalAttachmentProps } from 'components/FileUploader/types';

interface FileUploaderProviderProps {
  children: React.ReactNode;
}

export function FileUploaderProvider({ children }: FileUploaderProviderProps) {
  const [initialSelection, setInitialSelection] = useState<UploadFile[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<UploadFile[]>([]);
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [confirmProps, setConfirmProps] = useState<ConfirmProps | null>(null);
  const [filteredFiles, setFilteredFiles] = useState<FilteredFiles>({ count: 0, status: false });

  const validFiles = useMemo(() => selectedFiles.filter(({ valid }) => valid), [selectedFiles]);

  const contextValue = {
    initialSelection,
    setInitialSelection,
    selectedFiles,
    setSelectedFiles,
    validFiles,
    modalProps,
    setModalProps,
    modalVisible,
    setModalVisible,
    uploading,
    setUploading,
    confirmProps,
    setConfirmProps,
    filteredFiles,
    setFilteredFiles,
  };

  return <UploadContext.Provider value={contextValue}>{children}</UploadContext.Provider>;
}

export enum FileUploaderDisplayType {
  PrimaryButton = 'primary',
  SecondaryButton = 'secondary',
  ListItem = '',
}
interface Props {
  acceptedFileTypes?: string;
  action: ModalAction[] | ((args: ModalActionArgs[]) => Promise<ModalAction[] | undefined>);
  className?: string;
  directory?: boolean;
  disabled?: boolean;
  id?: string;
  isBulkUpload?: boolean;
  isMetadataUpload?: boolean;
  label: React.ReactNode;
  multiple: boolean;
  name?: string;
  onClose?: () => void;
  onComplete?: (args: { status: OnCompleteStatus }) => void;
  onFileComplete: (args?: ModalUploadCompleteInput) => void;
  displayType?: FileUploaderDisplayType;
  validation?: (filename: string) => boolean;
  uploadSession?: any;
  modalAttachmentProps?: ModalAttachmentProps;
}

export function FileUploader({
  acceptedFileTypes,
  action,
  className = 'fileUploader',
  directory,
  disabled = false,
  id = 'file',
  isBulkUpload = false,
  isMetadataUpload = false,
  label,
  multiple,
  name = 'files',
  onClose,
  onComplete,
  onFileComplete,
  displayType = FileUploaderDisplayType.PrimaryButton,
  validation,
  uploadSession,
  modalAttachmentProps,
}: Props) {
  const {
    initialSelection,
    setFilteredFiles,
    setInitialSelection,
    setModalProps,
    setModalVisible,
    setSelectedFiles,
    uploading,
  } = useUploadContext();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (fileInputRef.current && directory) {
      // cannot set these attributes directly on the input
      // @see https://stackoverflow.com/a/63809892/2121598
      fileInputRef.current.setAttribute('directory', 'true');
      fileInputRef.current.setAttribute('webkitdirectory', 'true');
    }
  }, [directory, fileInputRef]);

  function handleChange() {
    if (isBulkUpload && onClose) {
      onClose();
    }

    // The ternary here is specifically to satisfy a need with the MultipleInspectionUploadForm
    const filesArray: UploadFile[] =
      initialSelection.length > 0 && isBulkUpload
        ? initialSelection
        : Array.from(fileInputRef.current?.files ?? [], f => {
            const path = getFileDirectoryPath((f as FileData).webkitRelativePath ?? '');
            const name = f.name;
            const sourceName = `${path}${name}`;

            const uploadFile: UploadFile = {
              data: f as FileData,
              name,
              path,
              percent: 0,
              sourceName,
              type: directory ? 'directory' : 'file',
              valid: true,
            };
            return uploadFile;
          });

    // We only want to set an initialSelection if the initialSelection is empty and we're on
    // the metadataUpload step of MultipleInspectionUploadForm. Otherwise we'll have a weird
    // state when using the fileuploader in other areas.
    if (initialSelection.length === 0 && isMetadataUpload) {
      setInitialSelection(filesArray);
    }
    // Filter out all .DS_Store files from the selected files. ch14418
    const filteredFiles = filesArray.filter(file => file.data?.name !== '.DS_Store');

    setSelectedFiles(filteredFiles);
    setFilteredFiles({
      count: filesArray.length - filteredFiles.length,
      status: filesArray.length !== filteredFiles.length,
    });
    handleValidation();
    setModalProps({
      action,
      fileInputRef,
      isBulkUpload,
      isMetadataUpload,
      acceptedFileTypes,
      onClose,
      onComplete,
      onFileComplete,
      uploadSession,
      modalAttachmentProps,
    });

    setModalVisible(true);
  }

  function handleValidation() {
    setSelectedFiles(prevSelectedFiles => {
      return prevSelectedFiles.map(f => {
        const name = f.data?.name;
        const [cr2FileType, isCr2] = name ? checkCr2(name) : ['', false];

        if (acceptedFileTypes && directory) {
          const types = acceptedFileTypes.split(',').map(type => type.trim());
          f.valid = types.includes(isCr2 ? cr2FileType : f.data?.type ?? '');
        }

        if (f.valid && validation) {
          f.valid = validation(isCr2 ? convertPathToJpg(f.sourceName) : f.sourceName);
        }

        if (modalAttachmentProps?.validateFileName) {
          f.valid = modalAttachmentProps?.validateFileName(name);
        }
        return {
          ...f,
          key: f.sourceName,
        };
      });
    });
  }

  return (
    <StyledForm>
      {isBulkUpload ? (
        <Button
          className={`ant-btn ant-btn-primary`}
          onClick={handleChange}
          disabled={uploading || disabled}
        >
          Upload
        </Button>
      ) : (
        <>
          <label
            className={displayType && `ant-btn ant-btn-${displayType} ${className}`}
            htmlFor={id}
          >
            {label}
          </label>
          <input
            accept={directory ? undefined : acceptedFileTypes}
            disabled={uploading || disabled}
            id={id}
            multiple={multiple}
            name={name}
            onChange={handleChange}
            ref={fileInputRef}
            type="file"
          />
        </>
      )}
    </StyledForm>
  );
}
