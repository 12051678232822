import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';

import { Button, Dropdown } from 'antd5';
import { ItemType } from 'antd5/es/menu/hooks/useItems';
import { DownOutlined } from '@ant-design/icons';

import { Modal } from 'components/Modal';
import { DataTableContext } from '../index';
import { ColumnPicker } from './ColumnPicker';
import { columnPickerStorage } from './ColumnPicker/helpers/storage';
import { MultiSorter } from './MultiSorter';
import { multiSorterStorage } from './MultiSorter/helpers/storage';
import { TExtensionColumn, TSortableColumn, TMultiSortColumn } from './types';
import { TDropdownAction, TDataTableStorageKey } from 'components/DataTable/types';
import { TDisplayColumnData } from 'components/data/types';

type ViewsProps = {
  columnDefs: TDisplayColumnData[];
  storageKey?: TDataTableStorageKey;
  showColumnPicker: boolean;
  showMultiSorter: boolean;
  isPrioritizing?: boolean;
  showClearSortAndFilter: boolean;
  onClearSortAndFilter: () => void;
  isClearSortAndFilterDisabled: boolean;
  additionalItems: TDropdownAction[];
};

export const Views: React.FunctionComponent<ViewsProps> = ({
  columnDefs,
  storageKey,
  showColumnPicker,
  showMultiSorter,
  isPrioritizing,
  showClearSortAndFilter,
  onClearSortAndFilter,
  isClearSortAndFilterDisabled,
  additionalItems = [],
}) => {
  const [showColumnPickerModal, setShowColumnPickerModal] = useState(false);
  const [showMultiSorterModal, setShowMultiSorterModal] = useState(false);

  const columnPickerStorageManager = columnPickerStorage({ storageKey: storageKey?.COLUMN_PICKER });
  const multiSorterStorageManager = multiSorterStorage({ storageKey: storageKey?.MULTI_SORT });

  const { updateCustomizedColumnSet, updateMultiSortDef, multiSortDef, customizedColumnSet } =
    useContext(DataTableContext);

  const stringifiedColumnKeys = useMemo(
    () => JSON.stringify(columnDefs.map(({ key }) => key)),
    [columnDefs]
  );

  const reduceMultiSortSelection = useCallback(
    (sortedColumns: TMultiSortColumn[]) =>
      sortedColumns.reduce((acc: TSortableColumn[], col: TMultiSortColumn) => {
        const { key, sort } = col;
        const columnDefinition = key && columnDefs.find(columnDef => columnDef.key === key);
        if (columnDefinition) {
          return [...acc, { column: columnDefinition, sortDirection: sort }];
        }
        return acc;
      }, []),
    [columnDefs]
  );

  const updateSort = useCallback(
    sort => {
      const sortDef = reduceMultiSortSelection(sort ?? []);
      if (sortDef.length) {
        updateMultiSortDef(sortDef);
        multiSorterStorageManager.set(sort);
      } else {
        updateMultiSortDef(undefined);
        multiSorterStorageManager.remove();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reduceMultiSortSelection, updateMultiSortDef]
  );

  // Load multiSort from localStorage and apply
  useEffect(
    () => {
      if (showMultiSorter) {
        const savedSort = multiSorterStorageManager.get();
        if (savedSort) {
          updateSort(savedSort);
        }
      }
    },
    [stringifiedColumnKeys] // TODO: see if this works on initial render only - it should
  );

  const mapExtensionColumnsToDefinition = useCallback(
    (savedColumns: TExtensionColumn[]): (TDisplayColumnData & { visible: boolean })[] => {
      return savedColumns
        .map((column: TExtensionColumn) => {
          const columnDefinition = columnDefs.find(implementation => {
            const key = implementation.key ? 'key' : 'dataIndex';
            return implementation[key] === column.key;
          });
          if (columnDefinition) {
            return {
              ...columnDefinition,
              visible: column.visible,
            };
          }
          return null;
        })
        .filter((c): c is TDisplayColumnData & { visible: boolean } => !!c);
    },
    [columnDefs] // TODO: make sure this works with full defs, not just keys, might not matter
  );

  const updateCustomCols = useCallback(
    (customColumns?: TExtensionColumn[], isInitialRender?: boolean) => {
      const previouslySavedColumns: Pick<TExtensionColumn, 'key' | 'visible'>[] =
        columnPickerStorageManager.get();
      const columnsToSave: Pick<TExtensionColumn, 'key' | 'visible'>[] = (customColumns ?? []).map(
        ({ key, visible }) => ({ key, visible })
      );
      if (
        customColumns?.length &&
        (isInitialRender || !isEqual(previouslySavedColumns, columnsToSave))
      ) {
        const columnDefinitionsWithVisibility = mapExtensionColumnsToDefinition(customColumns);
        updateCustomizedColumnSet(columnDefinitionsWithVisibility);
        columnPickerStorageManager.set(columnsToSave);
      } else {
        updateCustomizedColumnSet(undefined);
        columnPickerStorageManager.remove();
      }
    },
    [stringifiedColumnKeys, mapExtensionColumnsToDefinition, updateCustomizedColumnSet]
  );

  // Load custom columns from localStorage and apply
  useEffect(() => {
    if (showColumnPicker) {
      const savedColumns = columnPickerStorageManager.get();
      if (savedColumns) {
        updateCustomCols(savedColumns, true);
      }
    }
  }, [stringifiedColumnKeys]);

  const handleCloseColumnPickerModal = ({
    orderedColumns,
  }: {
    orderedColumns?: TExtensionColumn[];
  }) => {
    if (orderedColumns) {
      updateCustomCols(orderedColumns);
    }
    setShowColumnPickerModal(false);
  };

  const handleCloseMultiSorterModal = ({
    sortedColumns,
  }: {
    sortedColumns?: TExtensionColumn[];
  }) => {
    if (sortedColumns) {
      updateSort(sortedColumns);
    }
    setShowMultiSorterModal(false);
  };

  // menu items for 'Views' dropdown
  const menuItems: ItemType[] = useMemo(() => {
    return [
      showColumnPicker && {
        label: 'Customize Columns',
        onClick: () => setShowColumnPickerModal(true),
        disabled: isPrioritizing,
      },
      showMultiSorter && {
        label: 'Multiple Sort',
        onClick: () => setShowMultiSorterModal(true),
      },
      showClearSortAndFilter && {
        label: `Clear Filter & Sort`,
        onClick: onClearSortAndFilter,
        disabled: isClearSortAndFilterDisabled,
      },
      ...additionalItems.map(({ content, onClick, disabled, hoverable = true }) => {
        return {
          label: content,
          onClick,
          disabled,
          ...(hoverable ? {} : { className: 'not-hoverable' }),
        };
      }),
    ]
      .filter(Boolean)
      .map((item, index) => {
        return { ...item, key: index };
      });
  }, [
    additionalItems,
    showColumnPicker,
    isPrioritizing,
    showMultiSorter,
    showClearSortAndFilter,
    onClearSortAndFilter,
    isClearSortAndFilterDisabled,
  ]);

  return (
    <>
      <Dropdown menu={{ items: menuItems }} trigger={['click']} placement="bottomLeft">
        <Button data-testid="datatable-views-button">
          Views <DownOutlined />
        </Button>
      </Dropdown>
      <Modal
        open={showColumnPickerModal}
        closable={false}
        title="Customize Columns"
        onCancel={() => setShowColumnPickerModal(false)}
        destroyOnClose
        footer={null}
      >
        <ColumnPicker
          columns={columnDefs}
          customizedColumnSet={customizedColumnSet}
          onClose={handleCloseColumnPickerModal}
        />
      </Modal>
      <Modal
        open={showMultiSorterModal}
        closable={false}
        title="Multiple Sort"
        onCancel={() => setShowMultiSorterModal(false)}
        destroyOnClose
        footer={null}
      >
        <MultiSorter
          columnDefs={columnDefs}
          multiSortDef={multiSortDef}
          onClose={handleCloseMultiSorterModal}
        />
      </Modal>
    </>
  );
};
