import { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { isEqual, memoize } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import { getXYBounds } from 'utils/geo';
import { notifyBugsnag } from 'utils/bugsnag';
import { DamageTooltip } from 'components/damages/DamageTooltip';
import OpenSeadragon, {
  CLICK_ZOOM_FACTOR,
  getTileSources,
  getOpenSeadragon4Viewer,
  OSD4ViewerWithSVG,
} from 'utils/openseadragon4';
import {
  AddItemFailedEvent,
  OpenFailedEvent,
  TileDrawingEvent,
  TileLoadFailedEvent,
  CanvasClickEvent,
} from 'openseadragon4';
import { usePrevious } from 'utils/hooks';
import { ImageContainer, ObservationPolygon, ThumbnailHeader } from './ImageViewer.style';
import { PictureTarget } from 'horizon/routes/Inspections/types';
import {
  AtlasGqlHorizonDamageObservation,
  AtlasGqlTargetBlade_External,
} from 'types/atlas-graphql';
import { getRotation } from './utils';
import { Controls } from './Controls';
import { useSignedUrlsExpiredMessaging } from 'utils/signed-urls';

type ImageProps = {
  isNewFullSizeImageViewer?: boolean;
  isThumbnail?: boolean;
  setObservation?: (id: string) => void;
  filter?: string;
  setFilter?: (newFilter: string) => void;
  image: { [key: string]: any };
  copyText?: any;
  defaultValue?: any;
  onEdit?: () => void;
  homography?: any;
  doAjaxLoad?: boolean;
  observations: (AtlasGqlHorizonDamageObservation & { selected: boolean })[];
  target?: PictureTarget;
  render?: (arg?: OSD4ViewerWithSVG) => JSX.Element | null;
  primaryIndicator?: React.ReactNode;
  thumbnailHeaderAttributes?: React.ReactNode;
};

export const Image: React.FunctionComponent<ImageProps> = ({
  isNewFullSizeImageViewer = false,
  isThumbnail = false,
  setObservation,
  filter,
  setFilter,
  image,
  defaultValue = {},
  homography,
  doAjaxLoad = false,
  observations = [],
  target,
  render = () => null,
  primaryIndicator,
  thumbnailHeaderAttributes,
}) => {
  const { state } = useLocation<{ useModal?: boolean }>();
  const [viewer, setViewer] = useState<OSD4ViewerWithSVG | undefined>();
  const [svg, setSvg] = useState<SVGElement>();
  const [useRotate, setUseRotate] = useState<boolean>(true);
  const [levels, setLevels] = useState<{ url: string; width: number; height: number }[]>();
  const [imageContainer, setImageContainer] = useState<HTMLDivElement | null>(null);
  const observationClickDisabled = useRef<boolean>(false);

  // update the ref everytime setObservation changes
  const setObservationRef = useRef(setObservation);
  useLayoutEffect(() => {
    setObservationRef.current = setObservation;
  }, [setObservation]);

  const zoomViewIn = useCallback(() => {
    const viewport = viewer?.viewport;
    if (viewport) {
      const zoom = viewport.getZoom() * CLICK_ZOOM_FACTOR;
      viewport.zoomTo(zoom);
    }
  }, [viewer]);

  const zoomViewOut = useCallback(() => {
    const viewport = viewer?.viewport;
    if (viewport) {
      const zoom = viewport.getZoom() * (1 / CLICK_ZOOM_FACTOR);
      viewport.zoomTo(zoom);
    }
  }, [viewer]);

  const resetZoom = () => {
    viewer?.viewport?.goHome();
  };

  const toggleRotate = useCallback(
    (useRotate: boolean) => {
      const viewport = viewer?.viewport;
      if (target && target.__typename === 'TargetBLADE_EXTERNAL') {
        const degrees = useRotate ? getRotation(target as AtlasGqlTargetBlade_External) : 0;
        viewport?.setRotation(degrees);
      }

      setUseRotate(useRotate);
    },
    [target, viewer]
  );

  const handleControls = ({
    zoomIn,
    zoomOut,
    reset,
    rotate,
    filter,
  }: {
    zoomIn?: boolean;
    zoomOut?: boolean;
    reset?: boolean;
    rotate?: boolean;
    filter?: string;
  }) => {
    zoomIn && zoomViewIn();
    zoomOut && zoomViewOut();
    reset && resetZoom();
    rotate !== undefined && toggleRotate(rotate);
    filter !== undefined && setFilter && setFilter(filter);
  };

  const handleObservationClick = (observationId?: string) => {
    if (setObservation && observationId && !observationClickDisabled.current) {
      setObservation(observationId);
    }
  };

  // This will apply the linear transformation declared by a "Level" control
  const transformImage = memoize((context: CanvasRenderingContext2D) => {
    const image = context.getImageData(0, 0, context.canvas.width, context.canvas.height);
    const originalData = new Uint8ClampedArray(image.data);

    return ({ a = 1, b = 0 } = {}) => {
      // Prevent repaints using a key
      const key = [a, b].toString();

      if (key === context.canvas.id) return image;

      if (a === 1 && b === 0) {
        image.data.set(originalData);
      } else {
        const data = new Uint8ClampedArray(originalData);

        for (let i = 0; i < data.length; i += 4) {
          for (let j = 0; j < 3; ++j) {
            data[i + j] = a * data[i + j] + b;
          }
        }

        image.data.set(data);
      }

      context.canvas.id = key;

      return image;
    };
  });

  // debounced to allow viewer to initialize
  const focusLayer = useDebouncedCallback(() => {
    const { coordinates } = observations.find(o => o.selected) || {};

    if (!coordinates) return;

    // Zoom to a point where the observation fits in the window, with a little breathing room
    const [[xMin, yMax], [xMax, yMin]] = getXYBounds(coordinates);
    const width = xMax - xMin;
    const height = yMax - yMin;
    const location = new OpenSeadragon.Rect(
      xMin - 0.1 * width,
      yMin - 0.2 * height,
      1.2 * width,
      1.4 * height
    );

    // ensures tab is in focus when moving the viewport
    window.requestAnimationFrame(() => {
      viewer?.viewport?.fitBoundsWithConstraints(location);
    });
  }, 300);

  const dziImage = image?.signedDziSchema?.dzi?.Image;

  // only set the expired messaging when the image viewer is visited directly
  // and not when it's been manually invoked by a user clicking on a thumbnail
  // image (e.g. from the inspection or damage details page). this will help
  // ensure that the messaging is not invoked twice.
  //
  // since this uses the `image.signedDziSchema` expiration and the details
  // pages use the `image.signedUrl` expiration, it's assumed these values are
  // the same, which we enforce in our GQL schema query.
  const expiresAt = !state?.useModal ? dziImage?.expiresAt : 0;
  useSignedUrlsExpiredMessaging(expiresAt);

  // intializes the openseadragon viewer
  useEffect(() => {
    const handleZoomKeys = ({ target, code }: { target: any; code: string }) => {
      if (target.form === undefined) {
        switch (code) {
          case 'Minus':
            return zoomViewOut;
          case 'Equal':
            return zoomViewIn;
          default:
            break;
        }
      }
    };

    if (imageContainer !== null) {
      const degrees =
        useRotate && target && target.__typename === 'TargetBLADE_EXTERNAL'
          ? getRotation(target as AtlasGqlTargetBlade_External)
          : 0;

      const osdViewer = getOpenSeadragon4Viewer({
        degrees,
        doAjaxLoad,
        dziImage,
        element: imageContainer,
      });

      setViewer(osdViewer);
      window.addEventListener('keydown', handleZoomKeys);
    }

    return () => {
      if (viewer) {
        viewer.destroy();
        setViewer(undefined);
      }

      window.removeEventListener('keydown', handleZoomKeys);
    };

    // when providing all the dependencies required, the thumbnail viewer will
    // "lose" the image after viewing the image modal. there is probably a
    // better solution than masking the eslint warning, but not today...
    // eslint-disable-next-line
  }, [imageContainer]);

  const previousViewer = usePrevious(viewer);

  // adds handlers to the viewer
  useEffect(() => {
    if (viewer && viewer !== previousViewer) {
      const svg = viewer.svgOverlay().node();

      // add handlers for error reporting
      viewer.addHandler('open-failed', ({ source, message }: OpenFailedEvent) => {
        notifyBugsnag(new Error(`open-failed.  ${source} : ${message}`));
      });
      viewer.addHandler('tile-load-failed', ({ tile, message }: TileLoadFailedEvent) => {
        notifyBugsnag(new Error(`tile-load-failed.  ${tile} : ${message}`));
      });
      viewer.addHandler('add-item-failed', ({ message }: AddItemFailedEvent) => {
        notifyBugsnag(new Error(`add-item-failed. ${message}`));
      });

      // add handlers for polygon click
      viewer.addHandler('canvas-click', function ({ originalTarget }: CanvasClickEvent) {
        if (
          originalTarget instanceof SVGPolygonElement &&
          originalTarget.classList.contains('observation-polygon')
        ) {
          const observationId = originalTarget.getAttribute('data-observationid');
          if (setObservationRef.current && observationId && !observationClickDisabled.current) {
            setObservationRef.current(observationId);
          }
        }
      });

      // Disable interaction with observations while the user is dragging the image
      viewer.addHandler('canvas-drag', () => {
        observationClickDisabled.current = true;
      });
      viewer.addHandler('canvas-drag-end', () => {
        setTimeout(() => {
          observationClickDisabled.current = false;
        }, 100);
      });

      focusLayer();
      setSvg(svg);
    }

    if (viewer && levels) {
      viewer.addHandler('tile-drawing', ({ rendered }: TileDrawingEvent) => {
        const context = rendered?.context2D;
        const image = transformImage(context)(levels as any);
        context.putImageData(image, 0, 0);
      });
    }
  }, [viewer, previousViewer, levels, focusLayer, transformImage]);

  const prevImage = usePrevious(image);
  const prevObservations = usePrevious({ observations });

  // updates the image rendered by the viewer
  useEffect(() => {
    const prevUrl = prevImage?.signedUrl?.url ? new URL(prevImage.signedUrl.url) : undefined;
    const newUrl = image?.signedUrl?.url ? new URL(image?.signedUrl?.url) : undefined;

    if (
      viewer &&
      (prevUrl?.pathname !== newUrl?.pathname ||
        prevUrl?.searchParams.get('filter') !== newUrl?.searchParams.get('filter'))
    ) {
      transformImage?.cache?.clear?.();
      setLevels(undefined);
      toggleRotate(useRotate);
      viewer.open(getTileSources(dziImage));
    }

    if (!isEqual(prevObservations?.observations, observations)) {
      focusLayer();
    }
  }, [
    dziImage,
    focusLayer,
    image?.signedUrl?.url,
    observations,
    prevImage?.signedUrl?.url,
    prevObservations,
    toggleRotate,
    transformImage?.cache,
    useRotate,
    viewer,
  ]);

  const imagePaneBox = isNewFullSizeImageViewer
    ? imageContainer?.getBoundingClientRect()
    : undefined;

  return (
    // data-private used to hide images from LogRocket: https://docs.logrocket.com/reference/dom
    <ImageContainer ref={setImageContainer} data-private={'logrocket-hide'}>
      {isThumbnail ? (
        <ThumbnailHeader hasControlsOnly={!primaryIndicator && !thumbnailHeaderAttributes}>
          {primaryIndicator}
          <Controls
            onChange={handleControls}
            homography={homography}
            value={{
              rotate: useRotate,
              filter,
              levels: defaultValue.levels,
            }}
            isThumbnail={true}
          />
          {thumbnailHeaderAttributes}
        </ThumbnailHeader>
      ) : (
        <Controls
          onChange={handleControls}
          homography={homography}
          value={{
            rotate: useRotate,
            filter,
            levels: defaultValue.levels,
          }}
        />
      )}
      {svg &&
        createPortal(
          <>
            {render(viewer)}
            <g className="observation-group">
              {observations.map((o, index) => {
                return (
                  <DamageTooltip
                    id={o.id}
                    key={`${o.id}_tip` + index}
                    onClick={() => handleObservationClick(o.id)}
                    isHorizonDamage={true}
                    isNewFullSizeImageViewer={isNewFullSizeImageViewer}
                    imagePaneBox={imagePaneBox}
                  >
                    <ObservationPolygon
                      className="observation-polygon"
                      data-observationid={o.id}
                      key={o.id}
                      selected={o.selected}
                      coordinates={o.coordinates}
                    />
                  </DamageTooltip>
                );
              })}
            </g>
          </>,
          svg
        )}
    </ImageContainer>
  );
};
