import styled from 'styled-components';

export const StyledBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem;
  background: lightyellow;
  border-bottom: 1px solid #eee;
  animation: slide-down 0.4s ease-out;
  span {
    margin-right: 0.5rem;
  }
  .anticon svg {
    font-size: 0.8rem;
    margin-left: 0.25rem;
    vertical-align: inherit;
  }

  @keyframes slide-down {
    0% {
      opacity: 0;
      margin-top: -40px;
    }

    100% {
      opacity: 1;
      margin-top: 0;
    }
  }
`;
