import { useState, useMemo, useEffect } from 'react';
import { flatten, startCase, lowerCase, intersection } from 'lodash';
import { Form, Select, Button } from 'antd';
import { Tooltip } from 'components/Tooltip';
import styled from 'styled-components';
import { ReportContext, ReportTemplatesForContext } from './types';

import { useGetReportDeliveryModesQuery } from 'types/atlas-graphql';
import { SelectValue } from 'antd/lib/select';

import { storage } from 'utils/storage';

export interface SubmitReportTemplateProp {
  id: string;
  variantId?: string;
}

export interface SubmitAdvancedReportProps {
  deliveryModeId: string;
  reportTemplates: Array<SubmitReportTemplateProp>;
}

interface Props {
  templates: ReportTemplatesForContext[];
  onSubmit: (props: SubmitAdvancedReportProps) => Promise<void>;
  onCancel: () => void;
  cacheKey?: string | null;
  reportContexts: ReportContext[];
}

const FormActions = styled.div`
  display: flex;
  button:first-child {
    margin: 0 0.5rem 0 auto;
  }
`;

const { Option } = Select;

function getFormattedName({ context, name }: { context: string; name: string }) {
  return `${startCase(lowerCase(context))}: ${name}`;
}

interface VariantIdLookup {
  [key: string]: string;
}

export function AdvancedReportForm(props: Props) {
  const { templates, onSubmit, onCancel, cacheKey, reportContexts } = props;
  const [form] = Form.useForm();

  const customerId = storage.getItem('customerId');
  const cachedReportConfig = cacheKey
    ? storage.getItem(`reports-${customerId}-${cacheKey}`, true)
    : null;

  let cachedTemplateIds = [],
    cachedDeliveryModeId = null,
    cachedVariantIds = {};

  cachedTemplateIds = cachedReportConfig?.reportTemplates?.map(({ id }) => id) || [];
  cachedDeliveryModeId = cachedReportConfig?.deliveryModeId;
  cachedVariantIds =
    cachedReportConfig?.reportTemplates?.reduce((acc, { id, variantId }) => {
      acc[id] = variantId;
      return acc;
    }, {}) || {};

  const [templateIds, setTemplateIds] = useState<SelectValue[]>(cachedTemplateIds);
  const [variantIdLookup, setVariantIdLookup] = useState<VariantIdLookup>(cachedVariantIds);

  useEffect(() => {
    const values = form.getFieldsValue(true);
    const { deliveryModeId } = values;
    if (!deliveryModeId) return;

    const allTemplates = flatten((templates || []).map(({ templates }) => templates)).map(
      ({ template }) => template
    );

    let clearField = false;
    (templateIds || []).forEach(id => {
      const template = allTemplates.find(t => t.id === id);
      if (!template?.deliveryCompatibility?.some(d => d.id === deliveryModeId)) {
        clearField = true;
      }
    });

    if (clearField) {
      form.setFieldsValue({ deliveryModeId: null });
    }
  }, [form, templates, templateIds]);

  const { data, loading: deliveryModesLoading } = useGetReportDeliveryModesQuery();

  const handleValuesChange = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === 'templateIds') {
      const value = changedValues[fieldName];
      setTemplateIds(value);
    }
  };
  const handleChangeVariant = (templateId: any, variantId: any) => {
    setVariantIdLookup({
      ...variantIdLookup,
      [templateId]: variantId,
    });
  };

  const handleFinish = ({
    templateIds,
    deliveryModeId,
  }: {
    templateIds: string[];
    deliveryModeId: string;
  }) => {
    const reportTemplates = templateIds.map((id: string) => ({
      id,
      variantId: variantIdLookup[id],
    }));
    if (cacheKey) {
      storage.setItem(
        `reports-${customerId}-${cacheKey}`,
        { reportTemplates, deliveryModeId },
        true
      );
    }
    return onSubmit({ deliveryModeId, reportTemplates });
  };

  const reportDeliveryModes = data?.reportDeliveryModes;

  const variantDropdowns = templateIds
    ?.map(tId => {
      const allTemplates = flatten((templates || []).map(({ templates }) => templates));
      const thisTemplate = allTemplates.find(t => t.template.id === tId);

      const template = thisTemplate?.template;
      const variants = template?.variants;

      if (!template || !variants || !variants.length) {
        return null;
      }

      return (
        <Form.Item
          label={`${getFormattedName({ context: template.context, name: template.name })} variant`}
          name={template.id}
          key={template.id}
        >
          <Select
            allowClear
            placeholder="Leave blank for default"
            onChange={variantId => handleChangeVariant(tId, variantId)}
          >
            {variants.map(({ id, name }: { id: string; name: string }) => {
              return (
                <Option key={id} value={id}>
                  {name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      );
    })
    .filter(Boolean);

  const deliveryModeOptions = useMemo(() => {
    const allTemplates = flatten((templates || []).map(({ templates }) => templates));
    const activeTemplates = allTemplates.filter(t => (templateIds || []).includes(t.template.id));

    // Only allow use of delivery modes that are compatible with all the selected
    // report templates. (ie. the intersection)
    const allowedDeliveryModes = intersection(
      ...activeTemplates.map(t => (t.template.deliveryCompatibility || []).map(({ id }) => id))
    );

    return (reportDeliveryModes || []).map(({ id, name }) => {
      const disabled = !allowedDeliveryModes.includes(id);
      return (
        <Option key={id} value={id} disabled={disabled}>
          <Tooltip
            title={disabled ? `Some templates are not compatible with delivery by ${name}` : null}
          >
            {name}
          </Tooltip>
        </Option>
      );
    });
  }, [templateIds, templates, reportDeliveryModes]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      onValuesChange={handleValuesChange}
      initialValues={{
        templateIds: cachedTemplateIds,
        deliveryModeId: cachedDeliveryModeId,
        ...cachedVariantIds,
      }}
    >
      <Form.Item
        key="templateIds"
        label="Templates"
        name="templateIds"
        rules={[{ required: true, message: 'Please select one or more templates.' }]}
      >
        <Select allowClear={true} mode="multiple" placeholder="Select report templates">
          {flatten((templates || []).map(({ templates }) => templates)).map(
            ({ template, disabled }) => {
              const { id, name, itemLimit, context } = template;
              const matchingContext = reportContexts.find(
                reportContext => reportContext?.context === context
              );
              return (
                <Option key={id} value={id} disabled={disabled}>
                  <Tooltip
                    title={
                      disabled
                        ? matchingContext?.toolTip
                          ? matchingContext?.toolTip
                          : `${getFormattedName({
                              context,
                              name,
                            })} reports are limited to ${itemLimit} items`
                        : null
                    }
                  >
                    {getFormattedName({ context, name })}
                  </Tooltip>
                </Option>
              );
            }
          )}
        </Select>
      </Form.Item>
      {variantDropdowns}
      {templateIds?.length ? (
        <Form.Item key="deliveryModeId" label="Delivery Mode" name="deliveryModeId">
          <Select allowClear placeholder="Select delivery mode" loading={deliveryModesLoading}>
            {deliveryModeOptions}
          </Select>
        </Form.Item>
      ) : undefined}
      <FormActions>
        <Button type="primary" htmlType="submit" disabled={!templateIds?.length}>
          Create
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </FormActions>
    </Form>
  );
}
