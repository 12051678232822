import { UploadFile } from '../types';

const cr2TypeRegex = /\.cr2/g;

export function convertPathToJpg(path: string) {
  return path.replace(/\.[a-z0-9]+$/i, '.jpg');
}

export function getFileDirectoryPath(path: string) {
  return path.replace(/(\/)?[^/]+$/, '$1');
}

export function checkCr2(name: string): [string, boolean] {
  const matchArray = name.toLowerCase().match(cr2TypeRegex);
  return [matchArray ? matchArray.pop() ?? '' : '', Boolean(matchArray)];
}

export async function extractJpgFromCr2(file: File | undefined) {
  if (!file) {
    throw new Error('[extractJpgFromCr2] Invalid file specified');
  }

  const { default: dcraw } = await import('utils/dcraw');
  const name = convertPathToJpg(file.name);
  const arrayBuffer = await file.arrayBuffer();
  const uIntArray = new Uint8Array(arrayBuffer);
  const thumbnail = dcraw(uIntArray, { extractThumbnail: true });
  const blob = new Blob([thumbnail], { type: 'image/jpeg' });

  return new File([blob], name, { lastModified: new Date().getTime(), type: blob.type });
}

export function isCr2File(file: UploadFile) {
  const [, isCr2] = checkCr2(file.data?.name ?? '');
  return isCr2;
}

export function updateIfCr2Files(files: UploadFile[]): UploadFile[] {
  return files.map(file => {
    let name = file.name;
    let sourceName = file.sourceName;

    if (isCr2File(file)) {
      name = convertPathToJpg(name);
      sourceName = convertPathToJpg(sourceName);
    }

    return {
      ...file,
      name,
      sourceName,
    };
  });
}
