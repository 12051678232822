import { useState, useEffect, useRef, useMemo, useCallback, useContext } from 'react';
import { useHistory } from 'react-router';

import { CardTabListType } from 'antd/lib/card';
import { Tooltip } from 'components/Tooltip';
import { Divider } from 'antd5';
import { sortBy, orderBy, flatten, uniq, isEqual, omit } from 'lodash';
import { FlexContainer, SpacedContainer } from 'utils/layouts/containers';
import { SelectableAction, useSelectableRecords } from 'utils/selectable';
import DataTableWithContext, {
  DataTableContextProvider,
  DataTableWithoutContext,
  PresetFilterType,
} from 'components/DataTable';
import { ExportReport } from 'components/ExportReport';
import { SchemaSelect } from 'components/damages/DamageSchema/SchemaSelect';
import { DamageList2ExpandedRow } from './ExpandedRow';
import { DamageComparer } from 'horizon/components/Damages/CompareOTron';
import { CreateTasksFromDamagesModal } from 'horizon/routes/Inspections/DamageList/CreateTasksFromDamagesModal';
import { DamageSearch } from 'horizon/components/Damages/DamageSearch';
import {
  DAMAGE_TABLE_LINK,
  NEW_IMAGE_VIEWER,
  DAMAGE_SORT_FILTER_IMPROVEMENTS,
} from 'utils/release-toggles';

import {
  damageIdColumn,
  tasksColumn,
  siteColumn,
  assetColumn,
  assetTypeColumn,
  assetStatusColumn,
  inspectionsColumn,
  observationsColumn,
  confirmationStatusColumn,
  getInspectionsColumn,
  getObservationsColumn,
  getPropagationsColumn,
  getAssetStatusColumn,
  getAssetColumn,
} from 'components/DamageTable2/columns';
import { EFilterSortClearType, TQueryFilter } from 'components/DataTable/types';
import { TDataQueryChangeArgs } from './types';
import { TTableColumnDef } from 'horizon/types/TableColumnDef';
import {
  AtlasGqlConfirmationStatus,
  AtlasGqlGetHorizonDamagesQuery,
  AtlasGqlHorizonDamage,
  AtlasGqlReportContext,
  GetHorizonDamagesDocument,
  useGetDescendantIdsQuery,
  AtlasGqlAsset,
  useAvailableComponentStatusesQuery,
  AtlasGqlAssetType,
} from 'types/atlas-graphql';

import { usePermissions, CanRender } from 'utils/usePermissions';
import { useAccountContext } from 'utils/account/AccountContext';
import { DAMAGE_EDIT_ATTRIBUTES } from 'utils/access-control/rules';
import { useDamageAttrs } from 'components/damages/withDamages';
import { generateDamageTableFilters } from 'horizon/routes/Inspections/DamageList/utils';
import { HORIZON_DAMAGE_TABLE_STORAGE_KEY } from 'components/DataTable/Views/helpers/tableStorageKeys';
import { useDamages2Banner } from 'horizon/components/Damages/Damages2Banner';

import { ComponentChooser } from '../choosers';
import { useManagedComponentChooser } from 'components/choosers/ComponentChooser/useManagedComponentChooser';
import { FilterControlsContainer } from './DamageTable2.style';
import { DamagesBulkEdit } from 'horizon/components/Damages/DamagesBulkEdit';
import { NOOP } from '../../utils/helpers';

import {
  MergeDamagesModal,
  MergeConfirmationState,
} from 'horizon/components/Damages/MergeDamagesModal';
import { DataTableContext } from 'components/DataTable';
import { columnPickerStorage } from 'components/DataTable/Views/ColumnPicker/helpers/storage';
import { multiSorterStorage } from 'components/DataTable/Views/MultiSorter/helpers/storage';

type DamageTable2Props = {
  id: string;
  title: string;
  damageIds?: string[];
  assetIds?: string[];
  assetTypes?: AtlasGqlAssetType[];
  controls?: boolean;
  critical?: boolean;
  defaultActiveTabKey?: string;
  externalLoading?: boolean;
};

const _DamageTable2: React.FunctionComponent<DamageTable2Props> = ({
  id,
  title,
  defaultActiveTabKey = 'ALL',
  damageIds,
  assetIds,
  assetTypes,
  critical = false,
  controls = true,
  externalLoading = false,
}) => {
  useDamages2Banner(true);
  const [filterBy, setFilterBy] = useState<TQueryFilter[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const rowKeys = useRef<string[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [allExpanded, setAllExpanded] = useState<boolean>(false);
  const [selectedSchema, setSelectedSchema] = useState<string>();
  const [schemaVisible, setSchemaVisible] = useState<boolean>(false);
  const [selectedAssets, setSelectedAssets] = useState<string[]>();
  const [damageComparerOpen, setDamageComparerOpen] = useState<boolean>(false);
  const [createTasksFromDamagesModalOpen, setCreateTasksFromDamagesModalOpen] = useState(false);
  const [damageSearchOpen, setDamageSearchOpen] = useState<boolean>(false);
  const [mergeConfirmationState, setMergeConfirmationState] = useState<MergeConfirmationState>({
    isOpen: false,
  });
  const [mergedDamageId, setMergedDamageId] = useState<string>();

  const columnPickerStorageManager = columnPickerStorage({
    storageKey: HORIZON_DAMAGE_TABLE_STORAGE_KEY.COLUMN_PICKER,
  });
  const multiSorterStorageManager = multiSorterStorage({
    storageKey: HORIZON_DAMAGE_TABLE_STORAGE_KEY.MULTI_SORT,
  });

  const {
    filteredInfo,
    updateFilteredInfo,
    updateSortedInfo,
    setPersistedState,
    updateCustomizedColumnSet,
    updateMultiSortDef,
    pagination,
  } = useContext(DataTableContext);
  const filteredSites: string[] = (filteredInfo as { [key: string]: any })?.site ?? [];

  const history = useHistory();
  const { HasRole } = usePermissions();
  const isEditor = HasRole({ role: 'role-editor' });

  const { hasReleaseToggle } = useAccountContext();
  const hasNewImageViewerReleaseToggle = hasReleaseToggle(NEW_IMAGE_VIEWER);
  const hasTableLinkReleaseToggle = hasReleaseToggle(DAMAGE_TABLE_LINK);
  const hasUnplannedTaskSchemaSelectorToggle = hasReleaseToggle('unplanned-tasks-schema-selector');
  const hasSortFilterImprovementsReleaseToggle = hasReleaseToggle(DAMAGE_SORT_FILTER_IMPROVEMENTS);

  const TableComponent = useMemo(
    () => (hasSortFilterImprovementsReleaseToggle ? DataTableWithoutContext : DataTableWithContext),
    [hasSortFilterImprovementsReleaseToggle]
  );

  const {
    selected = [],
    rowSelection,
    onClearSelection,
  } = useSelectableRecords<AtlasGqlHorizonDamage>();

  const { damageColumns, loading: loadingAttrs } = useDamageAttrs(selectedSchema, true, critical);

  const { onChange, loadState, componentChooserState, setComponentChooserState } =
    useManagedComponentChooser({
      componentId: `${id}-component-chooser`,
    });
  const { siteIds, turbineIds, componentIds, componentTypes } = componentChooserState;

  const { data: descendantsData, loading: descendantDataLoading } = useGetDescendantIdsQuery({
    variables: {
      filterBy: [{ key: 'assetIds', values: turbineIds ?? siteIds ?? [] }],
    },
    onCompleted: data => {
      setSelectedAssets([
        ...(turbineIds ?? siteIds ?? []),
        ...flatten(
          (data?.assetsWithMetadata?.items ?? []).map(({ descendants }) => [
            ...(descendants ?? [])
              .filter(
                ({ assetType }: AtlasGqlAsset) =>
                  !componentTypes?.length ||
                  (assetType?.name && componentTypes?.includes(assetType.name.toLowerCase()))
              )
              .map(({ id }) => id),
          ])
        ),
      ]);
    },
    skip: hasSortFilterImprovementsReleaseToggle || !!assetIds || (!turbineIds && !siteIds),
  });

  const allowedComponentTypeOptions: string[] = useMemo(
    () =>
      uniq(
        flatten(
          descendantsData?.assetsWithMetadata?.items
            ?.map(({ descendants }) =>
              descendants?.map(({ assetType }: AtlasGqlAsset) => assetType?.name)
            )
            .filter((n): n is string[] => !!n)
        )
      ),
    [descendantsData]
  );

  const { data: componentStatusData } = useAvailableComponentStatusesQuery({
    skip: !hasSortFilterImprovementsReleaseToggle,
  });

  useEffect(() => {
    if (!turbineIds && !siteIds) {
      setSelectedAssets(undefined);
    } else {
      if (componentTypes?.length) {
        /**
         * If at least one component type is selected, set selectedAssets to the
         * subset of descendants that match the selected type(s), and omit the selected
         * sites/turbines
         */
        setSelectedAssets([
          ...flatten(
            (descendantsData?.assetsWithMetadata?.items ?? []).map(({ descendants }) => [
              ...(descendants ?? [])
                .filter(({ assetType }: AtlasGqlAsset) => {
                  return (
                    assetType?.name &&
                    componentTypes?.includes(assetType.name.toLowerCase().replace(/\s/g, '_'))
                  );
                })
                .map(({ id }) => id),
            ])
          ),
        ]);
      } else {
        /**
         * If component types are deselected, set the selected assets back, to the
         * selected sites/turbines and all of their descendants
         */
        setSelectedAssets([
          ...(turbineIds ?? siteIds ?? []),
          ...flatten(
            (descendantsData?.assetsWithMetadata?.items ?? []).map(({ descendants }) => [
              ...(descendants ?? []).map(({ id }) => id),
            ])
          ),
        ]);
      }
    }
  }, [componentTypes, turbineIds, siteIds, descendantsData]);

  // If a previously filtered site is removed, remove any assets/components on that site as well
  useEffect(() => {
    const filters: { [key: string]: any[] } = filteredInfo as unknown as { [key: string]: any[] };
    if (filters && 'site' in filters && 'asset' in filters && !!filters.asset) {
      const sites: string[] | undefined = filters.site;
      const assetIds = filters.asset
        ?.find(({ key }) => key === 'assetId')
        ?.value?.filter((v: string) => sites?.includes(v.split(':')[1]));
      const componentIds = filters.asset
        ?.find(({ key }) => key === 'componentId')
        ?.value?.filter((v: string) => sites?.includes(v.split(':')[1]));

      updateFilteredInfo({
        ...filteredInfo,
        asset: [
          ...filters.asset?.filter(({ key }) => !['assetId', 'componentId'].includes(key)),
          assetIds?.length ? { key: 'assetId', value: assetIds } : undefined,
          componentIds?.length ? { key: 'assetId', value: componentIds } : undefined,
        ].filter(Boolean),
      });
    }
  }, [(filteredInfo as unknown as { [key: string]: any[] })?.site]);

  const selectedDamagesHaveSameAsset = useMemo(
    () => selected.every(damage => !!damage.asset?.id && damage.asset.id === selected[0].asset?.id),
    [selected]
  );

  const allColumns: TTableColumnDef[] = useMemo(
    () => [
      ...(hasSortFilterImprovementsReleaseToggle
        ? [
            damageIdColumn,
            confirmationStatusColumn,
            tasksColumn,
            siteColumn,
            getAssetColumn(filteredSites, assetTypes, assetIds),
            getAssetStatusColumn(componentStatusData?.assetFieldDefinition?.fieldOptions ?? []),
            getInspectionsColumn(),
            getObservationsColumn(),
            getPropagationsColumn(),
          ]
        : [
            omit(damageIdColumn, ['filterDropdown']),
            confirmationStatusColumn,
            omit(tasksColumn, ['filterDropdown', 'sorter', 'sortKey']),
            omit(siteColumn, ['filterProperty', 'filterDropdown', 'sortKey', 'sorter']),
            assetColumn,
            assetTypeColumn,
            assetStatusColumn,
            inspectionsColumn,
            observationsColumn,
            getPropagationsColumn(),
          ]),
      ...damageColumns,
    ],
    [damageColumns, componentStatusData, hasSortFilterImprovementsReleaseToggle, filteredSites]
  );

  const CONFIRMED_TAB_LIST: (CardTabListType & PresetFilterType)[] = useMemo(
    () => [
      {
        key: 'ALL',
        tab: 'All',
        value: {
          filters: { confirmationStatus: undefined },
          sortOrder: { columnKey: critical ? 'Date' : 'Severity', order: 'descend' },
        },
      },
      {
        key: 'CONFIRMED',
        tab: 'Confirmed',
        value: { filters: { confirmationStatus: [AtlasGqlConfirmationStatus.Confirmed] } },
      },
      {
        key: 'UNCONFIRMED',
        tab: 'Unconfirmed',
        value: {
          filters: {
            confirmationStatus: [
              AtlasGqlConfirmationStatus.NotConfirmed,
              AtlasGqlConfirmationStatus.ConfirmedUpdated,
            ],
          },
        },
      },
    ],
    [critical]
  );
  const initialPresetFilter = CONFIRMED_TAB_LIST.find(tab => tab.key === defaultActiveTabKey);

  // NOTE: these are necessary to synchronize the report export params with what is going on in the damage table
  const handleDataQueryChange = useCallback((args: TDataQueryChangeArgs) => {
    const { filterBy = [], totalCount = 0 } = args;
    setFilterBy(oldFilterBy => {
      if (isEqual(oldFilterBy, filterBy)) {
        return oldFilterBy;
      }
      return filterBy;
    });
    setTotalCount(totalCount);

    setAllExpanded(false);
    setExpandedRowKeys(oldExpandedRows => {
      if (oldExpandedRows.length === 0) {
        return oldExpandedRows;
      }
      return [];
    });
  }, []);

  const universalFilters: TQueryFilter[] = useMemo(
    () =>
      generateDamageTableFilters({
        schemaId: selectedSchema,
        assetIds: assetIds ?? componentIds ?? selectedAssets,
        assetTypes,
        damageIds,
        critical,
      }),
    [selectedSchema, assetIds, assetTypes, componentIds, selectedAssets, damageIds, critical]
  );

  const transformQueryResponse = useCallback((data: AtlasGqlGetHorizonDamagesQuery) => {
    if (!data.getHorizonDamages) {
      return { totalCount: 0, items: [] };
    }

    const totalCount = data.getHorizonDamages?.totalCount ?? 0;
    const items = (data.getHorizonDamages?.items ?? []).filter(Boolean) as AtlasGqlHorizonDamage[];

    rowKeys.current = items.map(({ id }: AtlasGqlHorizonDamage) => id);

    return {
      totalCount,
      items: items.map(damage => ({
        ...damage,
        attrs: damage.primaryObservationGroupAttrs,
      })),
    };
  }, []);

  const createTasksForSelectedDamages = (selected: AtlasGqlHorizonDamage[]) => {
    if (selected.length) {
      setCreateTasksFromDamagesModalOpen(true);
    }
  };

  // logic for setting the state of the expand/collapse all button
  useEffect(() => {
    if (expandedRowKeys.length === 0) {
      setAllExpanded(false);
    } else if (rowKeys.current.length === expandedRowKeys.length) {
      setAllExpanded(true);
    }
  }, [rowKeys, expandedRowKeys]);

  const handleChangeSchema = (schema: string) => {
    if (!!selectedSchema && schema !== selectedSchema) {
      // reset customized columns
      updateCustomizedColumnSet(undefined);
      columnPickerStorageManager.remove();
      // reset multisort
      updateMultiSortDef(undefined);
      multiSorterStorageManager.remove();

      // reset filters/sorters to their default values
      const { filters = {}, sortOrder = {} } = initialPresetFilter?.value ?? {};
      updateFilteredInfo(filters);
      updateSortedInfo(sortOrder);
      setPersistedState({ filteredInfo: filters, sortedInfo: sortOrder, pagination });
    }

    setSelectedSchema(schema);
  };

  // handler for expanding/collapsing rows
  const handleToggleExpandAll = () => {
    if (allExpanded) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys(rowKeys.current);
    }
  };

  const handleMergeSelected = useCallback(() => {
    const allObservations = flatten(
      flatten(
        selected.map(damage => (damage.observationGroups ?? []).map(g => g?.observations ?? []))
      )
    );

    // Find which damage has the oldest inspection and merge into that one
    const oldestObservation = sortBy(allObservations, ['inspection.inspectionDate'])[0];
    if (!!oldestObservation) {
      const targetDamageId: string = oldestObservation.damage?.id;
      setMergeConfirmationState({
        isOpen: true,
        targetDamageId,
        sourceDamageIds: uniq(
          allObservations
            .filter(o => o?.damage?.id && o.damage.id !== targetDamageId)
            .map(o => o?.damage?.id)
        ),
      });
    }
  }, [selected]);

  // arguments for damage report
  const damageReportArgs = [
    {
      key: 'filterBy',
      value: JSON.stringify([
        ...(filterBy || []),
        ...(universalFilters || []),
        ...(selected?.length
          ? [
              {
                key: 'id',
                operator: 'EQUALS',
                values: selected.map(({ id }) => id),
              },
            ]
          : []),
      ]),
    },
  ];

  // wait until loading is done and schema is selected before querying
  const skipQuery = controls && !selectedSchema;
  const loading = externalLoading || descendantDataLoading || loadingAttrs || skipQuery;

  return (
    <>
      <TableComponent
        id={id}
        title={title}
        loading={loading}
        dataQuery={GetHorizonDamagesDocument}
        onDataQueryChange={handleDataQueryChange}
        transform={transformQueryResponse}
        setTotalCount={setTotalCount}
        tabList={hasSortFilterImprovementsReleaseToggle ? undefined : CONFIRMED_TAB_LIST}
        initialPresetFilter={initialPresetFilter}
        clearSortAndFilterType={EFilterSortClearType.RESET}
        routeProps={{
          universalFilter: universalFilters,
          variables: { input: {} },
          skip: skipQuery,
        }}
        columns={allColumns}
        isExpandAllVisible={true}
        isExpandAllEnabled={true}
        allExpanded={allExpanded}
        handleExpandAll={handleToggleExpandAll}
        expandable={{
          expandedRowRender: (damage: AtlasGqlHorizonDamage) => (
            <DamageList2ExpandedRow
              damage={damage}
              selectedSchema={selectedSchema}
              lazyLoad={true}
              mergedDamageId={mergedDamageId}
              setMergedDamageId={setMergedDamageId}
            />
          ),
          expandedRowKeys,
          onExpandedRowsChange: (expandedKeys: string[]) => {
            setExpandedRowKeys(expandedKeys);
          },
        }}
        showColumnPicker={true}
        storageKey={HORIZON_DAMAGE_TABLE_STORAGE_KEY}
        controls={
          controls ? (
            <FilterControlsContainer
              hasUnplannedTaskSchemaSelectorToggle={hasUnplannedTaskSchemaSelectorToggle}
            >
              <SchemaSelect
                style={{ width: '200px' }}
                selected={selectedSchema}
                setSelected={handleChangeSchema}
                storageKey={`${id}-schema-select`}
                setSchemaVisible={setSchemaVisible}
              />
              {/* When we remove `rtv1:unplanned-tasks-schema-selector we can remove the check for hasUnplannedTaskSchemaSelectorToggle */}
              {hasUnplannedTaskSchemaSelectorToggle &&
                schemaVisible &&
                !hasSortFilterImprovementsReleaseToggle && (
                  <Divider type="vertical" style={{ height: '100%' }} />
                )}
              {/* When we remove `rtv1:unplanned-tasks-schema-selector we can just return false */}
              {!hasSortFilterImprovementsReleaseToggle && (
                <ComponentChooser
                  hasParentContainer={!hasUnplannedTaskSchemaSelectorToggle}
                  block={false}
                  settings={{
                    componentType: { allowComponentTypeWithMultipleTurbines: true },
                    component: { visible: true, disableComponentWithMultipleTurbines: true },
                  }}
                  onChange={loading ? NOOP : onChange}
                  allowedComponentTypeOptions={
                    allowedComponentTypeOptions.length ? allowedComponentTypeOptions : undefined
                  }
                  {...{
                    loadState,
                    componentChooserState,
                    setComponentChooserState,
                  }}
                />
              )}
            </FilterControlsContainer>
          ) : undefined
        }
        rowSelection={rowSelection}
        actions={
          <>
            <FlexContainer>
              <SelectableAction selected={selected} onClear={onClearSelection} />
              {!critical && (
                <ExportReport
                  reportContexts={[
                    {
                      context: AtlasGqlReportContext.HorizonDamage,
                      reportArguments: [
                        {
                          args: damageReportArgs,
                          itemCount: selected?.length || totalCount,
                        },
                      ],
                    },
                  ]}
                  disabled={false}
                  cacheKey="damage-list-2"
                />
              )}
            </FlexContainer>
            {!!selected.length && (
              <SpacedContainer>
                <CanRender
                  rules={[DAMAGE_EDIT_ATTRIBUTES]}
                  render={() => (
                    <DamagesBulkEdit
                      selected={selected}
                      columns={[...damageColumns, confirmationStatusColumn]}
                      onFinish={onClearSelection}
                    />
                  )}
                />
              </SpacedContainer>
            )}
          </>
        }
        additionalDropdownActions={[
          critical
            ? null
            : {
                content: 'Compare Selected',
                disabled: hasNewImageViewerReleaseToggle
                  ? selected.length !== 2
                  : selected.length < 2,
                onClick: () => {
                  if (hasNewImageViewerReleaseToggle) {
                    const group1 = selected[0].observationGroups?.find(
                      g => g?.isPrimaryObservationGroup
                    );
                    const group2 = selected[1].observationGroups?.find(
                      g => g?.isPrimaryObservationGroup
                    );
                    const pictureId1 = group1?.observations?.find(
                      o => o?.id === group1.sourceObservationId
                    )?.picture?.id;
                    const pictureId2 = group2?.observations?.find(
                      o => o?.id === group2.sourceObservationId
                    )?.picture?.id;

                    if (pictureId1 && pictureId2) {
                      history.push(
                        `/pictures/${pictureId1}?observation=${group1?.sourceObservationId}&picture2=${pictureId2}&observation2=${group2?.sourceObservationId}`,
                        { useModal: true }
                      );
                    }
                  } else {
                    setDamageComparerOpen(true);
                  }
                },
              },
          isEditor && hasTableLinkReleaseToggle
            ? {
                content: (
                  <Tooltip
                    title={
                      selected.length < 2 || selected.length > 20
                        ? 'Select between two and twenty damages to link.'
                        : !selectedDamagesHaveSameAsset
                          ? 'Damages are assigned to different assets.'
                          : ''
                    }
                  >
                    Link Damages
                  </Tooltip>
                ),
                disabled:
                  !hasNewImageViewerReleaseToggle ||
                  selected.length < 2 ||
                  selected.length > 20 ||
                  !selectedDamagesHaveSameAsset,
                onClick: handleMergeSelected,
              }
            : null,
          isEditor
            ? {
                content: `Create Task${selected.length > 1 ? 's' : ''}`,
                disabled: !selected.length,
                onClick: () => createTasksForSelectedDamages(selected),
              }
            : null,
          {
            content: 'ID Search',
            onClick: () => setDamageSearchOpen(true),
          },
        ].filter(Boolean)}
      />
      {!hasNewImageViewerReleaseToggle && selected.length > 1 && (
        <DamageComparer
          damageIds={orderBy(
            selected,
            [damage => damage.primaryObservationGroupAttrs?.Severity ?? 0],
            ['desc']
          ).map(({ id }) => id)}
          isOpen={damageComparerOpen}
          onClose={() => setDamageComparerOpen(false)}
        />
      )}
      <CreateTasksFromDamagesModal
        damages={selected}
        open={createTasksFromDamagesModalOpen}
        setOpen={setCreateTasksFromDamagesModalOpen}
      />
      <MergeDamagesModal
        mergeConfirmationState={mergeConfirmationState}
        onClose={() => {
          setMergeConfirmationState({ isOpen: false });
        }}
        onSuccess={damage => {
          // find selected rows that are no longer in the table
          // (i.e. the ones that were merged into the remaining damage)
          const selectedRows = selected.filter(
            ({ id }) => !mergeConfirmationState.sourceDamageIds?.includes(id)
          );

          // remove the now-merged rows from the table selection
          rowSelection.onChange(
            selectedRows.map(({ id }) => id),
            selectedRows
          );

          // set merged damage to update
          setMergedDamageId(damage.id);

          // for emphasis, deselect the remaining row after a delay
          setTimeout(() => {
            onClearSelection();
          }, 2500);
        }}
      />
      <DamageSearch isOpen={damageSearchOpen} onClose={() => setDamageSearchOpen(false)} />
    </>
  );
};

export const DamageTable2: React.FunctionComponent<DamageTable2Props> = props => {
  const { hasReleaseToggle } = useAccountContext();
  const hasSortFilterImprovementsReleaseToggle = hasReleaseToggle(DAMAGE_SORT_FILTER_IMPROVEMENTS);

  if (hasSortFilterImprovementsReleaseToggle) {
    return (
      <DataTableContextProvider {...omit(props, ['controls'])}>
        <_DamageTable2 {...props} />
      </DataTableContextProvider>
    );
  }

  return <_DamageTable2 {...props} />;
};
