import { Key, useState } from 'react';
import { isEqual, sortBy } from 'lodash';
import { Select, Button } from 'antd5';
import { FilterDropdownProps } from 'antd/lib/table/interface';

import {
  CustomFilterPopover,
  CustomFilterContainer,
  SelectContainer,
} from 'components/data/helpers/filters.style';
import { useGetSitesQuery } from 'types/atlas-graphql';
import { filterOption } from 'components/choosers/helpers';

export const SiteFilter: React.FunctionComponent<FilterDropdownProps> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const [previousSiteIds, setPreviousSiteIds] = useState<Key[]>(selectedKeys);
  const siteIds = selectedKeys || [];

  const { data: sitesData, loading: sitesLoading } = useGetSitesQuery();
  const sites = sortBy(sitesData?.sites?.items, ['name']) ?? [];

  const handleChange = (value: string[]) => {
    setSelectedKeys(value);
  };

  const handleConfirm = () => {
    setPreviousSiteIds(selectedKeys);
    confirm({ closeDropdown: true });
  };

  const handleReset = () => {
    setPreviousSiteIds([]);
    clearFilters && clearFilters();
  };

  return (
    <CustomFilterPopover className="ant-table-filter-dropdown">
      <CustomFilterContainer>
        <SelectContainer style={{ paddingRight: '2rem', width: '240px' }}>
          <Select
            id="site-filter-dropdown"
            placeholder={'Select Site...'}
            loading={sitesLoading}
            allowClear={true}
            showSearch={true}
            filterOption={filterOption}
            virtual={false}
            value={sitesLoading ? undefined : siteIds}
            onChange={handleChange}
            mode={'multiple'}
          >
            {sites.map(site => (
              <Select.Option key={site.id} className="site-filter-option" value={site.id}>
                {site.name}
              </Select.Option>
            ))}
          </Select>
        </SelectContainer>
      </CustomFilterContainer>
      <div className="ant-table-filter-dropdown-btns" style={{ borderTop: '1px solid #f0f0f0' }}>
        <Button type="link" size="small" onClick={handleReset}>
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={handleConfirm}
          disabled={isEqual(siteIds, previousSiteIds)}
        >
          OK
        </Button>
      </div>
    </CustomFilterPopover>
  );
};
