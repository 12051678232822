import { Fragment, useContext } from 'react';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Button, Tag, Divider } from 'antd';
import { StyledBanner } from './CustomizedDataBanner.style';

import { DataTableContext } from '../index';
import { columnPickerStorage } from '../Views/ColumnPicker/helpers/storage';
import { multiSorterStorage } from '../Views/MultiSorter/helpers/storage';

export function CustomizedDataBanner({ storageKey = {} }) {
  const { multiSortDef, updateMultiSortDef, customizedColumnSet, updateCustomizedColumnSet } =
    useContext(DataTableContext) || {};

  const columnPickerStorageManager = columnPickerStorage({ storageKey: storageKey?.COLUMN_PICKER });
  const multiSorterStorageManager = multiSorterStorage({ storageKey: storageKey?.MULTI_SORT });

  function handleClick() {
    // reset customized columns
    updateCustomizedColumnSet(undefined);
    columnPickerStorageManager.remove();
    // reset multisort
    updateMultiSortDef(undefined);
    multiSorterStorageManager.remove();
  }

  return (
    <StyledBanner>
      <div>
        {customizedColumnSet ? (
          <Fragment>
            <span>Visible columns are customized</span>
            <Divider type="vertical" />
          </Fragment>
        ) : null}
        {multiSortDef ? (
          <Fragment>
            <span>Multi-column sort applied:</span>
            {multiSortDef.map(({ column: { title }, sortDirection }, idx) => (
              <Tag key={idx}>
                {title}
                {sortDirection === 'ASC' ? <CaretUpOutlined /> : <CaretDownOutlined />}
              </Tag>
            ))}
          </Fragment>
        ) : null}
      </div>
      <Button type="link" onClick={handleClick}>
        Reset to default
      </Button>
    </StyledBanner>
  );
}
