import { createContext, useContext } from 'react';
import { ConfirmProps, FilteredFiles, ModalProps, UploadFile } from './types';

interface UploadContextType {
  confirmProps: ConfirmProps | null;
  filteredFiles: FilteredFiles;
  initialSelection: UploadFile[];
  modalProps: ModalProps | null;
  modalVisible: boolean;
  selectedFiles: UploadFile[];
  setConfirmProps: React.Dispatch<React.SetStateAction<ConfirmProps | null>>;
  setFilteredFiles: React.Dispatch<React.SetStateAction<FilteredFiles>>;
  setInitialSelection: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  setModalProps: React.Dispatch<React.SetStateAction<ModalProps | null>>;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  setUploading: React.Dispatch<React.SetStateAction<boolean>>;
  uploading: boolean;
  validFiles: UploadFile[];
}

export const defaultUploadContext: UploadContextType = {
  confirmProps: null,
  filteredFiles: { count: 0, status: false },
  initialSelection: [],
  modalProps: null,
  modalVisible: false,
  selectedFiles: [],
  setConfirmProps: () => {},
  setFilteredFiles: () => {},
  setInitialSelection: () => {},
  setModalProps: () => {},
  setModalVisible: () => {},
  setSelectedFiles: () => {},
  setUploading: () => {},
  uploading: false,
  validFiles: [],
};

export const UploadContext = createContext<UploadContextType>(defaultUploadContext);

export function useUploadContext() {
  return useContext(UploadContext);
}
