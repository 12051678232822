import { stringifyWithReplacer } from './jsonHelpers';

type StorageKey = 'customerId' | 'customerId:active' | 'disabled-release-toggles' | string;

/**
 * These storage key names should be JSON-encoded and JSON-decoded when adding
 * to and retrieving from storage, respectively.
 */
const jsonEncodedItems: StorageKey[] = ['disabled-release-toggles'];

/**
 * Default all items to be stored in localStorage, but allow us to override.
 * Items in this list are stored in sessionStorage. Using sessionStorage allows
 * the user to have multiple tabs open that represent different data/features.
 */
const sessionStorageItems: StorageKey[] = ['customerId:active'];

/**
 * To abstract the user's need to know where items are stored, retrieve the
 * store based upon the key requested.
 *
 * @param {StorageKey} key The storage key name
 * @returns {Storage} Local storage or Session storage depending on the key
 */
function getStore(key: StorageKey): Storage {
  return sessionStorageItems.includes(key) ? sessionStorage : localStorage;
}

export function getItem(key: StorageKey, parse = false): any {
  try {
    const item = getStore(key).getItem(key);
    if (item && (parse || jsonEncodedItems.includes(key))) {
      return JSON.parse(item);
    }
    return item;
  } catch (e) {
    console.error(`Unable to retrieve item from storage: ${key}`, e.message);
    return null;
  }
}

/**
 * Set an item into storage
 *
 * @param {StorageKey} key The storage key name
 * @param {any} value The data to add to storage at the key provided
 * @param {boolean} stringify When true, stringify the data before adding to storage
 */
export function setItem(key: StorageKey, value: any, stringify: boolean = false): void {
  try {
    const stringified =
      stringify || (jsonEncodedItems.includes(key) && typeof value !== 'string')
        ? stringifyWithReplacer(value)
        : String(value);
    getStore(key).setItem(key, stringified);
  } catch (e) {
    console.error(`Unable to add item to storage: ${key}`, e.message);
  }
}

/**
 * Remove an item from storage
 *
 * @param {StorageKey} key The storage key name
 */
export function removeItem(key: StorageKey) {
  try {
    getStore(key).removeItem(key);
  } catch (e) {
    console.error(`Unable to remove item from storage: ${key}`, e.message);
  }
}

/**
 * Remove multiple items from storage
 *
 * @param {StorageKey[]} keys The storage key names
 */
export function removeItems(keys: StorageKey[]) {
  if (Array.isArray(keys)) {
    keys.forEach(removeItem);
  }
}

/**
 * Retrieve an item from storage, and then delete it from storage
 *
 * @param {StorageKey} key The storage key name
 * @param {boolean} parse When true, parse the data after retrieving from storage
 * @returns any
 */
export function pluck(key: StorageKey, parse: boolean = false): any {
  const item = getItem(key, parse);
  removeItem(key);
  return item;
}

/**
 * Remove all the items from storage and sessionStorage
 */
export function clear() {
  try {
    localStorage.clear();
    sessionStorage.clear();
  } catch (e) {
    console.error('Unable to clear storage');
  }
}

const storageManager = ({ storageKey }: { storageKey: StorageKey }) => {
  return {
    get: () => (storageKey ? getItem(storageKey, true) : null),
    set: (value: any) => (storageKey ? setItem(storageKey, value, true) : {}),
  };
};

/**
 * Deprecated, just import the functions don't be silly
 *
 * @deprecated
 */
const storage = {
  clear,
  getItem,
  pluck,
  removeItem,
  removeItems,
  setItem,
};

export { storage, storageManager };
